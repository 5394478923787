<template>
  <div>
    <v-layout wrap justify-center>
      <v-flex xs12 lg11>
        <v-layout wrap justify-space-between py-2>
          <v-flex v-for="(item, i) in navItems" :key="i" px-2>
            <router-link
              style="text-decoration: none"
              :to="'/LandingPage/landingPage?content=' + item.name"
            >
              <v-btn
                block
                text
                :ripple="false"
                :style="{
                  border:
                    $route.query.content == item.name
                      ? '1px solid red'
                      : !$route.query.content && item.name == 'Top'
                      ? '1px solid red'
                      : 'none',
                }"
              >
                <span>
                  {{ item.name }}
                </span>
              </v-btn>
            </router-link>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout> 
    <v-layout wrap justify-center>
      <v-flex xs12>
        <Top v-if="$route.query.content == 'Top' || !$route.query.content" />
        <Benefits
          v-if="$route.query.content == 'Benefits' || !$route.query.content"
        />
        <BenefitsList v-if="$route.query.content == 'Benefits List'" />
        <Automation v-if="$route.query.content == 'Automation'" />
        <FashionRetailers v-if="$route.query.content == 'Fashion Retailers'" />
        <Growyourbusiness v-if="$route.query.content == 'Grow your business'" />
        <GrowList v-if="$route.query.content == 'Grow List'" />
        <Conclusion v-if="$route.query.content == 'Conclusion'" />
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import Top from "./top";
import Benefits from "./benefits";
import BenefitsList from "./benefitsList";
import Automation from "./automation";
import FashionRetailers from "./fashionRetailers";
import Growyourbusiness from "./growYourBusiness";
import GrowList from "./growList";
import Conclusion from "./conclusion";

export default {
  components: {
    Top,
    Benefits,
    BenefitsList,
    Automation,
    FashionRetailers,
    Growyourbusiness,
    GrowList,
    Conclusion
  },
  data() {
    return {
      navItems: [
        { name: "Top" },
        { name: "Benefits" },
        { name: "Benefits List" },
        { name: "Automation" },
        { name: "Fashion Retailers" },
        { name: "Grow your business" },
        { name: "Grow List" },
        { name: "Conclusion" },
      ],
    };
  },
};
</script>