var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-layout',{attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":"","lg11":""}},[_c('v-layout',{attrs:{"wrap":"","justify-space-between":"","py-2":""}},_vm._l((_vm.navItems),function(item,i){return _c('v-flex',{key:i,attrs:{"px-2":""}},[_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":'/LandingPage/landingPage?content=' + item.name}},[_c('v-btn',{style:({
                border:
                  _vm.$route.query.content == item.name
                    ? '1px solid red'
                    : !_vm.$route.query.content && item.name == 'Top'
                    ? '1px solid red'
                    : 'none',
              }),attrs:{"block":"","text":"","ripple":false}},[_c('span',[_vm._v(" "+_vm._s(item.name)+" ")])])],1)],1)}),1)],1)],1),_c('v-layout',{attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":""}},[(_vm.$route.query.content == 'Top' || !_vm.$route.query.content)?_c('Top'):_vm._e(),(_vm.$route.query.content == 'Benefits' || !_vm.$route.query.content)?_c('Benefits'):_vm._e(),(_vm.$route.query.content == 'Benefits List')?_c('BenefitsList'):_vm._e(),(_vm.$route.query.content == 'Automation')?_c('Automation'):_vm._e(),(_vm.$route.query.content == 'Fashion Retailers')?_c('FashionRetailers'):_vm._e(),(_vm.$route.query.content == 'Grow your business')?_c('Growyourbusiness'):_vm._e(),(_vm.$route.query.content == 'Grow List')?_c('GrowList'):_vm._e(),(_vm.$route.query.content == 'Conclusion')?_c('Conclusion'):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }