<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      spinner="bar-fade-scale"
      color="#1976d2"
      size="128"
      is-full-screen
    />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="black" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: white">
            {{ msg }}
          </span>
        </v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-center pb-4 px-3 pt-5>
      <v-flex xs12 text-left>
        <span style="font-size: 20px; font-weight: bold">
          Conclusion Section
        </span>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center>
      <v-flex xs12 md12 px-md-3 px-lg-3 px-xl-3>
        <v-layout wrap>
          <v-flex xs12 md12>
            <v-layout wrap>
              <v-flex xs12>
                <v-text-field
                  outlined
                  dense
                  v-model="landingpage.conclusion.title"
                  label="Title "
                ></v-text-field>
              </v-flex>
              <v-flex xs12>
                <v-text-field
                  outlined
                  dense
                  v-model="landingpage.conclusion.caption"
                  label="Caption"
                  
                ></v-text-field>
              </v-flex>
               <v-flex xs12>
                <v-text-field
                  outlined
                  dense
                  v-model="landingpage.conclusion.text"
                  label="Button Text"
                  
                ></v-text-field>
              </v-flex>
              <v-flex xs12>
                <v-textarea
                rows="8"
                  outlined
                  dense
                  v-model="landingpage.conclusion.buttonText"
                  label="Description"
                ></v-textarea>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex md12 pt-4>
            <v-layout justify-end>
              <v-flex md3>
                <v-btn
                  block
                  tile
                  color="black"
                  light
                  :ripple="false"
                  depressed
                  @click="validateInput"
                >
                  <span style="color: #ffffff">Save</span>
                </v-btn>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      landingpage: {
        conclusion: { title: null, caption: null,text:null,buttonText:null },
      },
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      appLoading: false,
      imageArray: [],
      imageError: "",
      imageCoverError: "",
      formData: new FormData(),
      selectedFiles: [],
      image: null,
      coverImageArray: [],
      selectedImage: null,
      topimageFile: null,
      imageFiles: [],
      id: null,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    validateInput() {
      if (!this.landingpage.conclusion.title) {
        this.msg = "Please Provide Title ";
        this.showSnackBar = true;
        return;
      } else if (!this.landingpage.conclusion.caption) {
        this.msg = "Please Provide Caption";
        this.showSnackBar = true;
        return;
      } else if (!this.landingpage.conclusion.text) {
       this.msg = "Please Provide Description";
        this.showSnackBar = true;
        return;
      }else if (!this.landingpage.conclusion.buttonText) {
       this.msg = "Please Provide Button Text";
        this.showSnackBar = true;
        return;
      } else {
        this.add();
      }
    },
  
    getData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/landingpage/get",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.landingpage = response.data.data;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    add() {
      axios({
        method: "post",
        url: "/landingpage/set",
        data: this.landingpage,
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.msg = " Updated Successully";
            this.showSnackBar = true;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
  },
};
</script>