<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      spinner="bar-fade-scale"
      color="#1976d2"
      size="128"
      is-full-screen
    />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="black" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: white">
            {{ msg }}
          </span>
        </v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-center pb-4 px-3 pt-5>
      <v-flex xs12 text-left>
        <span style="font-size: 20px; font-weight: bold"> Top </span>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center>
      <v-flex xs12 md12 px-md-3 px-lg-3 px-xl-3>
        <v-layout wrap>
          <v-flex xs12 md12>
            <v-layout wrap>
              <v-flex xs12>
                <v-text-field
                  outlined
                  dense
                  v-model="landingpage.top.title"
                  label="Title of the Top"
                ></v-text-field>
              </v-flex>
              <v-flex xs12>
                <v-text-field
                  outlined
                  dense
                  v-model="landingpage.top.caption"
                  label="Caption of the Top"
                ></v-text-field>
              </v-flex>
              <!-- <v-flex md12 xs12 pb-5 v-if="landingpage.top">
                <ImageComp
                  class="homeText"
                  :singleImage="landingpage.top.leftImage"
                  @stepper="winStepper"
                  :height="'450'"
                  :width="'450'"
                  :heading="'Upload Top Left Image'"
                  :componentType="'topleftimage'"
                />
              </v-flex> -->
              <v-layout justify-start wrap pl-2 pb-5>
                <v-flex xs12 v-if="imagePreviewLeft || landingpage.top">
                  <img
                    v-if="imagePreviewLeft"
                    :src="imagePreviewLeft"
                    v-show="showPreviewLeft"
                    width="300px"
                    height="250px"
                    class="align-center"
                    contain
                  />
                  <img
                    v-else
                    :src="baseURL + landingpage.top.leftImage"
                    width="300px"
                    height="250px"
                    class="align-center"
                    contain
                  />
                </v-flex>
                <v-flex xs12 sm7 md6 lg6>
                  <v-btn
                    width="180px"
                    block
                    tile
                    depressed
                    :ripple="false"
                    class="text-capitalize"
                    color="#F6F5FF"
                    @click="$refs.showImageLeft.click()"
                  >
                    <v-layout justify-center wrap>
                      <v-flex xs2 sm2 md2 text-left>
                        <v-icon style="color: #000000">mdi-upload</v-icon>
                      </v-flex>
                      <v-flex xs10 sm8 md10>
                        <span class="image">Upload Top Left Image</span>
                      </v-flex>
                    </v-layout>
                  </v-btn>
                  <input
                    v-show="false"
                    accept="image/*"
                    id="file"
                    ref="showImageLeft"
                    multiple="multiple"
                    type="file"
                    @change="showImageLeft"
                  />
                </v-flex>
              </v-layout>
              <v-layout justify-start wrap pl-2 pb-5>
                <v-flex xs12 v-if="imagePreviewRight || landingpage.top">
                  <img
                    v-if="imagePreviewRight"
                    :src="imagePreviewRight"
                    v-show="showPreviewRight"
                    width="300px"
                    height="250px"
                    class="align-center"
                    contain
                  />
                  <img
                    v-else
                    :src="baseURL + landingpage.top.rightImage"
                    width="300px"
                    height="250px"
                    class="align-center"
                    contain
                  />
                </v-flex>
                <v-flex xs12 sm7 md6 lg6>
                  <v-btn
                    width="180px"
                    block
                    tile
                    depressed
                    :ripple="false"
                    class="text-capitalize"
                    color="#F6F5FF"
                    @click="$refs.showImageRight.click()"
                  >
                    <v-layout justify-center wrap>
                      <v-flex xs2 sm2 md2 text-left>
                        <v-icon style="color: #000000">mdi-upload</v-icon>
                      </v-flex>
                      <v-flex xs10 sm8 md10>
                        <span class="image">Upload Top Right Image</span>
                      </v-flex>
                    </v-layout>
                  </v-btn>
                  <input
                    v-show="false"
                    accept="image/*"
                    id="file"
                    ref="showImageRight"
                    multiple="multiple"
                    type="file"
                    @change="showImageRight"
                  />
                </v-flex>
              </v-layout>
              <v-layout justify-start wrap pl-2 pb-5>
                <v-flex xs12 v-if="imagePreviewBottom || landingpage.top">
                  <img
                    v-if="imagePreviewBottom"
                    :src="imagePreviewBottom"
                    v-show="showPreviewBottom"
                    width="300px"
                    height="250px"
                    class="align-center"
                    contain
                  />
                  <img
                    v-else
                    :src="baseURL + landingpage.top.bottomImage"
                    width="300px"
                    height="250px"
                    class="align-center"
                    contain
                  />
                </v-flex>
                <v-flex xs12 sm7 md6 lg6>
                  <v-btn
                    width="180px"
                    block
                    tile
                    depressed
                    :ripple="false"
                    class="text-capitalize"
                    color="#F6F5FF"
                    @click="$refs.showImageBottom.click()"
                  >
                    <v-layout justify-center wrap>
                      <v-flex xs2 sm2 md2 text-left>
                        <v-icon style="color: #000000">mdi-upload</v-icon>
                      </v-flex>
                      <v-flex xs10 sm8 md10>
                        <span class="image">Upload Top Botttom Image</span>
                      </v-flex>
                    </v-layout>
                  </v-btn>
                  <input
                    v-show="false"
                    accept="image/*"
                    id="file"
                    ref="showImageBottom"
                    multiple="multiple"
                    type="file"
                    @change="showImageBottom"
                  />
                </v-flex>
              </v-layout>
              <!-- <v-flex md12 xs12 pb-5 v-if="landingpage.top">
                <ImageComp
                  class="homeText"
                  @stepper="winStepper"
                  :singleImage="landingpage.top.rightImage"
                  :height="'612'"
                  :width="'614'"
                  :heading="'Upload Top Right Image'"
                  :componentType="'toprightimage'"
                />
              </v-flex>
              <v-flex md12 xs12 pb-5 v-if="landingpage.top">
                <ImageComp
                  class="homeText"
                  :singleImage="landingpage.top.bottomImage"
                  @stepper="winStepper"
                  :height="'568'"
                  :width="'490'"
                  :heading="'Upload Top Bottom Image'"
                  :componentType="'topbottomimage'"
                />
              </v-flex> -->
            </v-layout>
          </v-flex>
          <v-flex md12 pt-4>
            <v-layout justify-end>
              <v-flex md3>
                <v-btn
                  block
                  tile
                  color="black"
                  light
                  :ripple="false"
                  depressed
                  @click="validateInput"
                >
                  <span style="color: #ffffff">Save</span>
                </v-btn>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
// import ImageComp from "./ImageCropper";
export default {
  components: {
    // ImageComp,
  },
  data() {
    return {
      landingpage: {
        top: { caption: null, title: null },
      },
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      appLoading: false,
      imageArray: [],
      imageError: "",
      imageCoverError: "",
      formData: new FormData(),
      selectedFiles: [],
      image: null,
      coverImageArray: [],
      file1: null,
      file2: null,
      file3: null,
      imagePreviewLeft: null,
      imagePreviewRight: null,
      imagePreviewBottom: null,
      showPreviewLeft: null,
      showPreviewRight: null,
      showPreviewBottom: null,
      formDataLeft: new FormData(),
      formDataRight: new FormData(),
      formDataBottom: new FormData(),
      selectedImage: null,
      toprightimageFile: null,
      topleftimageFile: null,
      topbottomimageFile: null,
      imageFiles: [],
      id: null,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    validateInput() {
      // if (!this.landingpage.areaOfFocus.topCaption) {
      //   this.msg = "Please Provide Top caption of Area of Focus";
      //   this.showSnackBar = true;
      //   return;
      // }
      // else
      if (!this.landingpage.top.title) {
        this.msg = "Please Provide Title of Benefits";
        this.showSnackBar = true;
        return;
      } else if (!this.landingpage.top.caption) {
        this.msg = "Please Provide Caption of Benefits";
        this.showSnackBar = true;
        return;
      } else {
        this.add();
      }
    },
    winStepper(window_data) {
      if (window_data.type == "topleftimage") {
        this.topleftimageFile = window_data.selectedFiles;
      } else if (window_data.type == "toprightimage") {
        this.toprightimageFile = window_data.selectedFiles;
      } else if (window_data.type == "topbottomimage") {
        this.topbottomimageFile = window_data.selectedFiles;
      }
    },
    getData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/landingpage/get",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.landingpage = response.data.data;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    add() {
      axios({
        method: "post",
        url: "/landingpage/set",
        data: this.landingpage,
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            if (this.imagePreviewRight) {
              this.uploadrightImage();
            }
            if (this.imagePreviewLeft) {
              this.uploadleftImage();
            }
            if (this.imagePreviewBottom) {
              this.uploadbottomImage();
            }
            this.msg = " Updated Successully";
            this.showSnackBar = true;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    showImageLeft(e) {
      this.file1 = e.target.files[0];
      var reader = new FileReader();
      reader.onload = (e) => {
        this.$refs.showImageLeft.value = null;
        this.imagePreviewLeft = e.target.result;
      };
      if (this.file1.size > 2145728) {
        this.msg = "File Should be less than 2MB";
        this.showSnackBar = true;
        return;
      }
      this.showPreviewLeft = true;
      if (this.file1) {
        if (/\.(jpe?g|png|gif)$/i.test(this.file1.name)) {
          reader.readAsDataURL(this.file1);
        }
      }
    },
    uploadleftImage() {
      this.formDataLeft.append("type", "top_leftImage");
      this.formDataLeft.append("image", this.file1);
      axios({
        method: "POST",
        url: "/landingpage/image",
        data: this.formDataLeft,
        headers: {
          "Content-Type": "multipart/form-data",
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            this.formDataLeft = new FormData();
            this.msg = "Upload Successfully";
            this.showSnackBar = true;
            this.dialog = false;
            this.editdialog = false;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.msg = "Can't Upload Cover Image.. Please Try Again Later";
          this.showSnackBar = true;
          console.log(err);
        });
    },
    showImageRight(e) {
      this.file2 = e.target.files[0];
      var reader = new FileReader();
      reader.onload = (e) => {
        this.$refs.showImageRight.value = null;
        this.imagePreviewRight = e.target.result;
      };
      if (this.file2.size > 2145728) {
        this.msg = "File Should be less than 2MB";
        this.showSnackBar = true;
        return;
      }
      this.showPreviewRight = true;
      if (this.file2) {
        if (/\.(jpe?g|png|gif)$/i.test(this.file2.name)) {
          reader.readAsDataURL(this.file2);
        }
      }
    },
    uploadrightImage() {
      this.formDataRight.append("type", "top_rightImage");
      this.formDataRight.append("image", this.file2);
      axios({
        method: "POST",
        url: "/landingpage/image",
        data: this.formDataRight,
        headers: {
          "Content-Type": "multipart/form-data",
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            this.formDataRight = new FormData();
            this.msg = "Upload Successfully";
            this.showSnackBar = true;
            this.dialog = false;
            this.editdialog = false;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.msg = "Can't Upload Cover Image.. Please Try Again Later";
          this.showSnackBar = true;
          console.log(err);
        });
    },
    showImageBottom(e) {
      this.file3 = e.target.files[0];
      var reader = new FileReader();
      reader.onload = (e) => {
        this.$refs.showImageBottom.value = null;
        this.imagePreviewBottom = e.target.result;
      };
      if (this.file3.size > 2145728) {
        this.msg = "File Should be less than 2MB";
        this.showSnackBar = true;
        return;
      }
      this.showPreviewBottom = true;
      if (this.file3) {
        if (/\.(jpe?g|png|gif)$/i.test(this.file3.name)) {
          reader.readAsDataURL(this.file3);
        }
      }
    },
    uploadbottomImage() {
      this.formDataBottom.append("type", "top_bottomImage");
      this.formDataBottom.append("image", this.file3);
      axios({
        method: "POST",
        url: "/landingpage/image",
        data: this.formDataBottom,
        headers: {
          "Content-Type": "multipart/form-data",
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            this.formDataBottom = new FormData();
            this.msg = "Upload Successfully";
            this.showSnackBar = true;
            this.dialog = false;
            this.editdialog = false;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.msg = "Can't Upload Cover Image.. Please Try Again Later";
          this.showSnackBar = true;
          console.log(err);
        });
    },
  },
};
</script>