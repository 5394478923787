<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      spinner="bar-fade-scale"
      color="#1976d2"
      size="128"
      is-full-screen
    />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="black" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: white">
            {{ msg }}
          </span>
        </v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout justify-center wrap>
      <v-flex xs12 md12 px-md-3 px-lg-3 px-xl-3 pt-5>
        <v-layout wrap>
          <v-flex xs6>
            <span style="font-size: 20px; font-weight: bold"
              >Benefits List</span
            >
          </v-flex>
          <v-flex xs6 text-right>
            <v-dialog
              :retain-focus="false"
              v-model="dialog"
              persistent
              max-width="600px"
              :key="dialog"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn outlined v-bind="attrs" v-on="on"> Add </v-btn>
              </template>
              <v-card>
                <v-form>
                  <v-card-title>
                    <span class="headline">Add Benefits</span>
                  </v-card-title>
                  <v-card-text>
                    <v-container>
                      <v-layout justify-center wrap>
                        <v-flex xs12>
                          <v-text-field
                            outlined
                            dense
                            v-model="benefits.title"
                            label="Title of the Benefits"
                          ></v-text-field>
                        </v-flex>
                        <v-flex xs12>
                          <v-textarea
                            outlined
                            dense
                            v-model="benefits.content"
                            label="Content of the Benefits"
                          ></v-textarea>
                        </v-flex>
                        <v-layout justify-center wrap>
                          <v-flex xs12>
                            <span>Select Color</span>
                          </v-flex>
                        </v-layout>
                        <v-flex xs12>
                          <v-color-picker
                            dot-size="25"
                            hide-mode-switch
                            mode="hexa"
                            swatches-max-height="200"
                            v-model="benefits.color"
                          ></v-color-picker>
                        </v-flex>
                      </v-layout>
                    </v-container>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="dialog = false">
                      Close
                    </v-btn>
                    <v-btn color="blue darken-1" text @click="validateInput()">
                      Save
                    </v-btn>
                  </v-card-actions>
                </v-form>
              </v-card>
            </v-dialog>
          </v-flex>
        </v-layout>
        <v-layout wrap justify-start v-if="area.length > 0">
          <v-flex v-for="(item, i) in area" :key="i" md4 pa-4>
            <v-card style="line-height: 16px">
              <v-layout wrap justify-center px-2 py-4>
                <v-flex xs12>
                  <span> {{ item.title }} </span><br /><br />
                  <v-avatar :color="item.color" size="15" /><br /><br />
                  <span
                    class="texttt"
                    style="
                      font-size: 13px;
                      font-weight: bold;
                      color: #000000;
                    "
                  >
                    {{ item.content }}
                  </span>
                </v-flex>
                <v-flex pt-5 xl6 lg6 md6 pb-2 sm6 xs6 px-5 justify-center>
                  <v-dialog v-model="item.delete" max-width="600px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        small
                        class="mr-2"
                        outlined
                        block
                        v-on="on"
                        v-bind="attrs"
                        color="red"
                      >
                        Delete
                      </v-btn>
                    </template>
                    <v-card>
                      <v-card-title
                        >Are you sure you want to delete this
                        Benefits?</v-card-title
                      >
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="blue darken-1"
                          text
                          @click="item.delete = false"
                          >Cancel</v-btn
                        >
                        <v-btn
                          color="blue darken-1"
                          text
                          @click="deleteItem(item)"
                          >OK</v-btn
                        >
                        <v-spacer></v-spacer>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-flex>
                <v-flex pt-5 xl6 lg6 md6 pb-2 sm6 xs6 px-5 justify-center>
                  <v-btn
                    small
                    class="mr-2"
                    outlined
                    block
                    color="green"
                    @click="editSlider(item)"
                  >
                    Edit
                  </v-btn>
                </v-flex>
                <br />
              </v-layout>
            </v-card>
          </v-flex>
        </v-layout>
        <v-dialog v-model="editdialog" max-width="800px">
          <v-card>
            <v-card-title>
              <span class="headline">Edit Benefits</span>
            </v-card-title>
            <v-card-text>
              <v-layout justify-center wrap>
                <v-flex xs12 md12 lg12>
                  <v-text-field
                    outlined
                    dense
                    v-model="editingitem.title"
                    label=" Title of the Benefits"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 md12 lg12>
                  <v-textarea
                    outlined
                    dense
                    v-model="editingitem.content"
                    label=" Content of the Benefits"
                  ></v-textarea>
                </v-flex>
                <v-layout justify-center wrap>
                  <v-flex xs12>
                    <span>Select Color</span>
                  </v-flex>
                </v-layout>
                <v-flex xs12>
                  <v-color-picker
                    dot-size="25"
                    hide-mode-switch
                    mode="hexa"
                    swatches-max-height="200"
                    v-model="editingitem.color"
                  ></v-color-picker>
                </v-flex>
              </v-layout>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="editdialog = false">
                Close
              </v-btn>
              <v-btn color="blue darken-1" text @click="edit(editingitem._id)">
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      benefits: { title: null, content: null, color: null },
      area: [],
      editingitem: [],
      appLoading: false,
      dialog: false,
      editdialog: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      imageArray: [],
      imageError: "",
      imageCoverError: "",
      formData: new FormData(),
      selectedFiles: [],
      image: null,
      coverImageArray: [],
      formDataCover: new FormData(),
      selectedImage: null,
      topimageFile: null,
      imageFiles: [],
      id: null,
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    validateInput() {
      if (!this.benefits.title) {
        this.msg = "Please Provide Title of the benefits";
        this.showSnackBar = true;
        return;
      } else if (!this.benefits.content) {
        this.msg = "Please Provide Content of the benefits";
        this.showSnackBar = true;
        return;
      } else {
        this.add();
      }
    },
    getList() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/landingpage/platform/getlist",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.area = response.data.data;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    add() {
      this.benefits["color"] = this.benefits.color.hexa;
      axios({
        method: "post",
        url: "/landingpage/platform/add",
        data: this.benefits,
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.id = response.data.id;
            Object.keys(this.benefits).forEach(
              (key) => (this.benefits[key] = null)
            );
            this.dialog = false;
            this.getList();
            this.msg = " Added Successully";
            this.showSnackBar = true;
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    edit() {
      this.appLoading = true;
      var user = {};
      user["title"] = this.editingitem.title;
      user["content"] = this.editingitem.content;
      user["color"] = this.editingitem.color.hexa;
      user["id"] = this.editingitem;
      axios({
        method: "POST",
        url: "/landingpage/platform/edit",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: user,
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.editdialog = false;
            this.getList();
          } else {
            this.msg = "Can't Update";
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    editSlider(item) {
      this.editingitem = item;
      this.editdialog = true;
    },
    deleteItem(r) {
      var data = {};
      data["id"] = r._id;
      axios({
        url: "/landingpage/platform/remove",
        method: "POST",
        data: data,
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.delete = false;
          this.appLoading = false;
          if (response.data.status) {
            this.msg = "Delete Sucessfully";
            this.showsnackbar = true;
            this.getList();
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>
<style>
.icon {
  padding-left: 170px;
}
.v-application .primary {
  background-color: red !important;
}
.texttt {
  line-height: 1.5em;
  min-height: 9em;
  float: left;
}
</style>